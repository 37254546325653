import React, { useRef, useEffect, useState } from 'react';
import { FaArrowCircleDown } from "react-icons/fa"
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { throttle } from 'lodash';
import { easeIn, frame, useAnimate } from 'framer-motion';
import './App.css'; // Import your CSS here

gsap.registerPlugin(ScrollTrigger);


const texts = [
  ``,
  `a fully automated cleaning robot
  designed for hard surfaces in large facilities such as
  warehouses, factories, shopping malls and airports`,
  ``,
  `this powerful and efficient robot cleans floors
  using water, detergents and a brushing action,
  to remove dust, dirt and stains and dries the surface`,
  ``,
  `with advanced technology, sweep independently
  maps, navigates, avoids obstacles and cleans
  without any need for human intervention`,
  ``,
  `sweep comes with an intuitive and easy to use app,
  making onboarding easy and allowing for detailed
  scheduling and reporting`,
  `and its self-sufficient docking station handles charging
  and waste disposal, ensuring complete autonomy`
]

const CanvasSequence = () => {
  const canvasRef = useRef(null);
  const frameCount = 311;
  const images = useRef([]);
  const sequence = useRef({ frame: 0 });
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [showIndicator, setShowIndicator] = useState(true);
  const [capturedIndex, setCapturedIndex] = useState(null);
  const [keyframe, setKeyframe] = useState(0);
  const [textKeyframe, setTextKeyFrame] = useState(-1);

  const [mainTextScope, animateMainText] = useAnimate();
  const [textScope, animateText] = useAnimate();

  const disableScroll = () => document.body.classList.add("stop-scrolling");
  const enableScroll = () => document.body.classList.remove("stop-scrolling");

  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  const mainTextAnimations = [[{ opacity: 0, x: 0, y: 0 }, { duration: 0 }], [{ opacity: 1, x: isMobile ? -70 : -400, y: 0 }, { duration: 0.5 }], [{ opacity: 1, y: isMobile ? -60 : -180 }, { duration: 0.5 }], [{ opacity: 0, y: 0 }, { duration: 0 }]]
  const textAnimations = [[{ opacity: 0, x: isMobile ? -100 : -360, y: 0 }, { duration: 1 }], [{ opacity: 1, x: isMobile ? -100 : -360, y: 0 }, { duration: 1.5 }], [{ opacity: 0, x: isMobile ? -130 : -390, y: isMobile ? 10 : 180 }, { duration: 0 }], [{ opacity: 1, x: isMobile ? -80 : -390, y: isMobile ? 10 : 50 }, { duration: 0.25, easeIn: "linear" }], [{ opacity: 0, x: isMobile ? -100 : -350, y: isMobile ? -50 : -300 }, { duration: 0, easeIn: "linear" }], [{ opacity: 1, x: isMobile ? -100 : -400, y: isMobile ? -50 : -250 }, { duration: 1, easeIn: "linear" }], [{ opacity: 0, x: 0, y: isMobile ? -80 : -280 }, { duration: 0.5, easeIn: "linear" }], [{ opacity: 1, x: isMobile ? 120 : 450, y: -50 }, { duration: 1, easeIn: "linear" }], [{ opacity: 1, x: isMobile ? 120 : 450, y: isMobile ? -50 :-250 }, { duration: 0.5, easeIn: "linear" }], [{ opacity: 1, x: 600, y: -60 }, { duration: 0 }], [{ opacity: 0, x: 500, y: 100 }, { duration: 0 }]]

  const handleIndex = (val) => {
    if (val < 15) {
      setKeyframe(0);
    }
    else if (val >= 15 && val < 45) {
      setTextKeyFrame(0);
      setKeyframe(1);
    }
    else if (val >= 45 && val < 80) {
      setTextKeyFrame(1);
      setKeyframe(2);
    }
    else if (val >= 80 && val < 100) {
      setKeyframe(3);
      setTextKeyFrame(2);
    }
    else if (val >= 100 && val < 130) {
      setTextKeyFrame(3);
    }
    else if (val >= 130 && val < 140) {
      setTextKeyFrame(4);
    }
    else if (val >= 140 && val < 190) {
      setTextKeyFrame(5);
    }
    else if (val >= 190 && val < 210) {
      setTextKeyFrame(6)
    }
    else if (val >= 210 && val < 260) {
      setTextKeyFrame(7);
    }
    else if (val >= 260) {
      setTextKeyFrame(8)
    }
  }

  useEffect(() => {
    handleIndex(capturedIndex);
  }, [capturedIndex])

  useEffect(() => {
    if (keyframe > -1) {
      animateMainText(mainTextScope.current, ...mainTextAnimations[keyframe]);
    }
  }, [keyframe])

  useEffect(() => {
    if (textKeyframe > -1) {
      animateText(textScope.current, ...textAnimations[textKeyframe])
    }
  }, [textKeyframe]);

  useEffect(() => {
    const preloadImages = () => {
      disableScroll();
      let loadedImagesCount = 0;

      for (let i = 0; i < frameCount; i++) {
        const img = new Image();
        img.src = currentFrame(i);
        img.onload = () => {
          loadedImagesCount++;
          if (loadedImagesCount === frameCount) {
            setImagesLoaded(true);
          }
        };
        img.onerror = () => {
          console.error(`Failed to load image: ${currentFrame(i)}`);
          loadedImagesCount++;
          if (loadedImagesCount === frameCount) {
            setImagesLoaded(true);
          }
        };
        images.current.push(img);
      }
    };

    preloadImages();
  }, []);

  useEffect(() => {
    if (!imagesLoaded) return;
    enableScroll();

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const canvasWidth = window.innerWidth;
    const canvasHeight = window.innerHeight;
    const scale = window.devicePixelRatio;

    canvas.width = Math.floor(canvasWidth * scale);
    canvas.height = Math.floor(canvasHeight * scale);
    canvas.style.width = `${canvasWidth}px`;
    canvas.style.height = `${canvasHeight}px`;

    context.scale(scale, scale);

    const animation = gsap.to(sequence.current, {
      frame: frameCount - 1,
      snap: 'frame',
      ease: "linear",
      scrollTrigger: {
        trigger: '.track',
        pin: '.canvas-container',
        start: 'top',
        end: !isMobile ? '+=800%' : "+=500%",
        pinSpacing: false,
        onLeaveBack: () => ScrollTrigger.refresh(),
        onEnterBack: () => ScrollTrigger.refresh(),
        scrub: !isMobile ? true : 1.75,
        onEnter: () => {
          setShowIndicator(true);
        },
        onLeave: () => ScrollTrigger.refresh()
      },
      onUpdate: !isMobile ? () => {
        if (sequence.current.frame > 3) {
          setShowIndicator(false);
        }

        setCapturedIndex(sequence.current.frame);
        render(sequence.current.frame);
      } : throttle(() => {        if (sequence.current.frame > 3) {
        setShowIndicator(false);
      }

      setCapturedIndex(sequence.current.frame);
      render(sequence.current.frame);
        if (sequence.current.frame > 3) {
          setShowIndicator(false);
        }

        setCapturedIndex(sequence.current.frame);
        render(sequence.current.frame);
      }, 40),
    });

    function render(frame) {
      if (frame < 23) {
        frame = 23;
      }

      context.clearRect(0, 0, canvas.width, canvas.height);
      let img = images.current[frame];

      if (img) {
        const imgWidth = img.width;
        const imgHeight = img.height;
        const scale = Math.min(canvasWidth / imgWidth, canvasHeight / imgHeight);
        const x = (canvasWidth / 2) - (imgWidth / 2) * scale;
        const y = (canvasHeight / 2) - (imgHeight / 2) * scale;

        context.drawImage(img, 0, 0, imgWidth, imgHeight, x, y, imgWidth * scale, imgHeight * scale);
      }
      else {
        img = images.current[frameCount - 1];

        const imgWidth = img.width;
        const imgHeight = img.height;
        const scale = Math.min(canvasWidth / imgWidth, canvasHeight / imgHeight);
        const x = (canvasWidth / 2) - (imgWidth / 2) * scale;
        const y = (canvasHeight / 2) - (imgHeight / 2) * scale;

        context.drawImage(img, 0, 0, imgWidth, imgHeight, x, y, imgWidth * scale, imgHeight * scale);
      }

    }

    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
      animation.kill();
    };
  }, [imagesLoaded]);

  const currentFrame = index => (
    `https://vlad-andrei-dobrescu.github.io/sweep-landing-photos/mobile_${(index + 1).toString().padStart(3, '0')}.jpg`
  );

  return (
    <div className="canvas-container">
      {!imagesLoaded && <div className="w-full h-full items-center flex justify-center text-white">
        <img className='animate-pulse h-32 w-32 mt-32' src="agora_logo_white.png" />
      </div>}
      <div className='h-full w-full relative flex items-center justify-center relative'>
        {showIndicator && imagesLoaded && <div className='absolute text-white'>
          <FaArrowCircleDown className='h-16 w-16 animate-bounce' />
        </div>}
        <canvas className="png-sequence" ref={canvasRef} />
        <div ref={mainTextScope} id="display-text" className='flex flex-col text-left absolute -space-y-2 md:-space-y-5 text-white opacity-0 font-rajdhani text-lg md:text-6xl font-bold md:mr-0 md:ml-16 mr-36 pl-2'>
          <span>meet</span>
          <span className='text-xl md:text-8xl'>sweep</span>
        </div>
        <div ref={textScope} className='px-16 md:block absolute h-10 text-white opacity-0 font-rajdhani text-xs md:text-2xl w-64 md:w-96 font-bold'>
          {texts[textKeyframe]}
        </div>
      </div>
    </div>
  );
};

export default CanvasSequence;